// Bootstrap overrides
$primary: #098fd5;

$bg-dark: #29323c;
$bg-light: #485563;
$bg-main: #f0f8ff;
$bg-footer: rgba(41, 50, 60, 0.2);
$nav-hover: rgba(0, 0, 0, 0.2);
$nav-child: rgba(0, 0, 0, 0.17);
$darkgray: #a5a5a5;
$lightgray: #dedede;
$whitish: #efefef;
$broken-white: #fcfcfc;
