@import 'assets/stylesheets/abstracts';

.wrapper {
  @include flex-center(column);
  height: 100%;

  .icon {
    font-size: 8rem;
    color: $danger;
  }

  .text {
    font-size: 3rem;
    line-height: 1;
  }

  .number {
    font-size: 5rem;
    line-height: 1;
  }
}
