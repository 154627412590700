@import 'assets/stylesheets/abstracts';

.wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
  height: 40px;

  .subscription {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    width: 100%;
    flex: 1 1 auto;

    .button {
      .divider {
        margin: 0 10px;
      }
    }
  }

  .subscriptions {
    width: 100%;
    flex: 1 1 auto;
  }
}
