@import 'assets/stylesheets/abstracts';

.companies-select {
  width: 100%;
  padding: 10px;

  hr {
    width: 100%;
    border-width: 2px;
    margin: 20px auto;
  }
}
