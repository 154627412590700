@import 'assets/stylesheets/abstracts';

.post-form {
  @include flex-center(row);
  width: 100%;
  height: 100%;
  margin-top: 20px;

  .left-arrow,
  .right-arrow {
    .icon {
      font-size: 1.3rem;
    }
  }

  .form {
    @include flex-center(column);
    width: 100%;
    height: 100%;

    .form-wrapper {
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column;
      flex: 1 1 auto;
      width: 100%;
      overflow-y: auto;
      padding: 5px;

      .wrapped {
        height: 100%;

        @include media-breakpoint-up(sm) {
          height: initial;
        }

        .banner {
          height: 200px;
          width: 100%;
          margin-bottom: 1rem;

          &:after {
            padding: 0;
          }

          img {
            height: initial;
            width: 100%;
          }
        }

        .wrapper {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: row;

          .left-input {
            padding-right: 5px;
            flex: 1;
          }

          .right-input {
            padding-left: 5px;
            flex: 1;
          }
        }

        .downer {
          @include flex-center(column);
          width: 100%;

          @include media-breakpoint-up(sm) {
            flex-direction: row;
          }

          .left {
            width: 100%;

            @include media-breakpoint-up(sm) {
              width: 70%;
            }
          }

          .right {
            width: 100%;

            @include media-breakpoint-up(sm) {
              width: 30%;
            }
          }
        }
      }
    }

    footer {
      @include flex-center(column-reverse);
      width: 100%;
      height: fit-content;
      margin-top: 10px;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: flex-end;
      }

      .buttons {
        width: 100%;
        margin: 0;
        padding: 5px 0;

        @include media-breakpoint-up(sm) {
          width: 25%;
          padding: 0 0 0 10px;
        }

        button {
          width: 100%;
        }
      }
    }
  }
}
