@import 'assets/stylesheets/abstracts';

.list {
  padding: 0 10px;
  flex: 1 1 auto;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
  height: 100%;

  @include media-breakpoint-up(lg) {
    padding-top: 10px;
    max-height: initial;
  }

  .no-comments {
    width: 100%;
    height: 100%;
    @include flex-center(row);
    font-size: 5rem;
    color: $gray-300;
  }

  .comment {
    @include flex-center(row);
    width: 100%;
    min-height: 35px;
    background: $lightgray;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    word-break: break-word;

    .avatar {
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      border: 1px solid $darkgray;
      background: $whitish;
      width: 35px;
      height: 35px;
      flex: 0 1 auto;

      //@include media-breakpoint-up(lg) {
      //  width: 100px;
      //  height: 100px;
      //}

      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      .content {
        @include flex-center(column);
        position: absolute;
        width: 100%;
        height: 100%;

        .icon {
          color: $darkgray;
          font-size: 1rem;

          @include media-breakpoint-up(sm) {
            font-size: 1.5rem;
          }

          @include media-breakpoint-up(md) {
            font-size: 2rem;
          }
        }
      }
    }

    .content {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      flex-wrap: nowrap;
      flex: 1 1 auto;
      width: 100%;
      padding: 0 10px;

      .body {
        margin: 0;

        .name {
          font-weight: bold;
          margin-right: 10px;
        }
      }

      .date {
        width: 100%;
        text-align: right;
        font-size: small;
      }
    }
  }
}
