@import 'assets/stylesheets/abstracts';

.main {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;

  .main-wrapper {
    width: 100%;
    padding: 20px 50px 0;
    background: $bg-main;
    display: flex;
    flex-direction: column;

    .main-content {
      flex: 1 0 auto;
    }

    .footer {
      flex-shrink: 0;
      margin: 20px -50px 0;
      padding: 40px 0 20px;
      background: $bg-footer;

      .disclaimer {
        font-size: xx-small;
        width: 90%;
        margin: auto;
        text-align: center;

        @include media-breakpoint-up(md) {
          width: 50%;
        }
      }

      .logos {
        @include flex-center(row);
        flex-wrap: wrap;

        @include media-breakpoint-up(md) {
          margin: auto;
          padding: 0 40px;
          max-width: 900px;
        }

        .programme-logo {
          text-align: center;
          width: 100%;
          margin: 20px 0;

          @include media-breakpoint-up(lg) {
            width: 40%;
          }

          img {
            width: 80%;
          }
        }

        .programme-slogan {
          width: 50%;
          text-align: right;

          @include media-breakpoint-up(lg) {
            width: 25%;
            text-align: center;
          }

          img {
            width: 65%;
          }
        }

        .eu-emble {
          width: 50%;

          @include media-breakpoint-up(lg) {
            width: 35%;
          }

          img {
            width: 100%;
          }
        }
      }
    }
  }
}
