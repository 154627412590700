@import 'assets/stylesheets/abstracts';

.form-wrapper {
  @include flex-center(row);
  padding: 0 30px;

  .form {
    @include flex-center(row);
    flex-wrap: wrap;

    .inputs {
      width: 100%;
      flex: 1 1 auto;
      margin-bottom: 10px;
    }

    .buttons {
      width: 100%;
      flex: 1 1 auto;

      @include media-breakpoint-up(sm) {
        width: initial;
      }
    }
  }
}
