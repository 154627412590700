@import 'assets/stylesheets/abstracts';

.wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-bottom: 20px;

  .image-wrapper {
    border-radius: 10px;
    margin: auto;
    overflow: hidden;
    position: relative;
    border: 1px solid $darkgray;
    background: $whitish;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 60%;
    }

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    .content {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .icon {
        color: $darkgray;
        font-size: 2rem;

        @include media-breakpoint-up(sm) {
          font-size: 3rem;
        }

        @include media-breakpoint-up(md) {
          font-size: 5rem;
        }
      }
    }
  }

  .name {
    text-align: center;
    color: $white;
    margin-top: 10px;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
      font-size: large;
    }

    @include media-breakpoint-up(md) {
      font-size: larger;
    }
  }
}
