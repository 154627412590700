@import 'assets/stylesheets/abstracts';

.sidebar {
  @include sidebar-base('../../assets/images/modal-image-1.jpg');

  .sidebar-text {
    height: fit-content;
    text-transform: uppercase;
    font-weight: bold;
    color: $white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-size: xx-large;
      margin-bottom: -10px;

      @include media-breakpoint-only(md) {
        font-size: x-large;
      }
    }

    .subtitle {
      font-size: x-large;

      @include media-breakpoint-only(md) {
        font-size: large;
      }
    }
  }
}
