@import 'assets/stylesheets/abstracts';

.form-wrapper {
  @include flex-center(row);
  width: 100%;
  margin: 10px 0;
  flex: 0 1 auto;

  .count {
    flex: 0 1 auto;
    padding: 0 10px;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    button {
      min-width: 80px;
      font-size: small;
      padding: 0;
    }
  }

  .form {
    flex: 1 1 auto;
    padding-left: 10px;

    .inputs {
      @include flex-center(row);

      .input {
        flex: 1 1 auto;
        padding-right: 10px;
        margin: 0;
      }

      .button {
        flex: 0 1 auto;
        padding-right: 10px;
        margin: 0;
      }
    }
  }
}
