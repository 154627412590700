html,
body,
main,
article,
section,
aside {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 0;
}

a[role='button'] {
  cursor: pointer;
}
