@import 'assets/stylesheets/abstracts';

.logos {
  @include flex-center(row);
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    margin: auto;
    padding: 0 40px;
    max-width: 900px;
  }

  .programme-logo {
    text-align: center;
    width: 100%;
    margin: 20px 0;

    @include media-breakpoint-down(md) {
      width: 33%;
    }

    @include media-breakpoint-up(lg) {
      width: 40%;
    }

    img {
      width: 80%;
    }
  }

  .programme-slogan {
    width: 50%;
    text-align: right;

    @include media-breakpoint-down(md) {
      width: 33%;
    }

    @include media-breakpoint-up(lg) {
      width: 25%;
      text-align: center;
    }

    img {
      width: 65%;
    }
  }

  .eu-emble {
    width: 50%;

    @include media-breakpoint-down(md) {
      width: 33%;
    }

    @include media-breakpoint-up(lg) {
      width: 35%;
    }

    img {
      width: 100%;
    }
  }
}
