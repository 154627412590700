@import 'assets/stylesheets/abstracts';

.post-topics-wrapper {
  .post-topics {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    @include media-breakpoint-up(sm) {
      justify-content: center;
    }

    @include media-breakpoint-up(sm) {
      justify-content: center;
    }

    .topic {
      @include flex-center(row);
      position: relative;
      width: 100%;
      height: 170px;
      max-width: 350px;
      border: 1px solid $gray-600;
      border-radius: 10px;
      text-align: center;
      margin: 10px;
      background: $white;

      @include media-breakpoint-up(sm) {
        width: 80%;
      }

      @include media-breakpoint-up(md) {
        width: 45%;
      }

      .add {
        font-size: xx-large;
      }
    }
  }
}
