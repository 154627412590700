@import 'assets/stylesheets/abstracts';

.dashboard-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;

  .title {
    flex: 0 1 auto;
  }

  .wrapper {
    flex: 1 1 auto;
    padding: 10% 0;

    .dashboard {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

      .box {
        @include flex-center(column);
        flex: 0 1 auto;
        width: 100%;
        padding: 0.8rem 1rem;
        border: 1px solid $gray-600;
        border-radius: 10px;
        text-align: center;
        background: $broken-white;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
          width: 48%;
        }

        @include media-breakpoint-up(lg) {
          width: 30%;
        }

        .inner {
          width: 100%;

          .number {
            font-size: 3rem;
            font-weight: bold;
            float: left;
            line-height: 1;
            margin-right: 10px;

            @include media-breakpoint-up(sm) {
              font-size: 3.5rem;
            }
          }

          .text {
            text-align: left;
            font-size: 1.2rem;
            font-weight: normal;
            line-height: 1.3;
            margin: 0;

            @include media-breakpoint-up(sm) {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}
