@import 'assets/stylesheets/abstracts';

.preview-image {
  width: 100%;
  background: $whitish;
  position: relative;
  @include flex-center(column);

  @include media-breakpoint-up(sm) {
    width: 70%;
  }

  &.error-message {
    border: 1px solid $danger;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .image-upload-content {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include flex-center(column);

    .close {
      z-index: 1;
      position: absolute;
      color: $white;
      font-size: 5rem;
      text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
      transition: font-size 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        font-size: 5.5rem;
      }
    }

    .error-icon {
      color: $danger;
      font-size: 5rem;
    }

    .icon-text {
      color: $danger;
      font-size: 1rem;
      text-align: center;
      word-wrap: break-spaces;
      width: 80%;
    }

    img {
      height: 100%;
    }
  }
}

.image-upload-wrapper {
  position: relative;
  border: 1px solid $darkgray;
  background: $whitish;
  width: 100%;
  cursor: pointer;

  @include media-breakpoint-up(sm) {
    width: 70%;
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .image-upload-content {
    position: absolute;
    width: 100%;
    height: 100%;
    @include flex-center(column);

    .icon {
      color: $darkgray;
      font-size: 4rem;
    }

    .icon-text {
      color: $darkgray;
      font-size: 1.25rem;
    }
  }
}
