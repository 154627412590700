@import 'assets/stylesheets/abstracts';

.comment {
  @include flex-center(column);
  width: 100%;

  @include media-breakpoint-up(lg) {
    flex-direction: column-reverse;
    height: 100%;
  }
}
