@import 'assets/stylesheets/abstracts';

.competence-wrapper {
  max-width: 600px;
  width: 100%;
  margin: auto;

  .header {
    @include flex-center(row);

    .left {
      flex: 1 1 auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;

      .name {
        font-size: initial;
        margin-right: 10px;
      }

      .button {
        padding: 0 5px;
        font-size: initial;

        &.secondary {
          color: $secondary;

          &:hover {
            color: $primary;
          }
        }

        &.danger {
          color: $secondary;

          &:hover {
            color: $danger;
          }
        }
      }
    }

    .right {
      flex: 0 1 auto;

      .button {
        padding: 0 5px;
        font-size: large;
        line-height: normal;

        &.secondary {
          color: $secondary;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  .answers {
    @include flex-center(column);
    border-radius: 0.25rem;
    overflow: hidden;
    margin-bottom: 1rem;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
      align-items: stretch;
    }

    .answer {
      width: 100%;
      border-radius: 0;

      @include media-breakpoint-up(sm) {
        @include flex-center(row);
        border-radius: initial;
      }

      &.active,
      &:hover {
        background: $bg-dark;
        border-color: $bg-dark;
      }
    }
  }
}
