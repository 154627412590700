#root {
  height: 100%;
}

.public {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;

  .public-wrapper {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      width: 75%;
    }
  }
}

.invalid-feedback {
  display: block;
}
