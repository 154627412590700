@import 'assets/stylesheets/abstracts';

.form {
  width: 100%;

  .form-wrapper {
    @include flex-center(row);
    flex-wrap: wrap;
    flex: 1 1 auto;

    .user,
    .position {
      width: 100%;
    }

    .admin,
    .button {
      text-align: center;
      width: 50%;
    }

    @include media-breakpoint-up(sm) {
      .user,
      .position {
        width: 32.5%;
      }

      .admin {
        width: 25%;
      }

      .button {
        width: 10%;
      }
    }

    .inputs {
      margin: 5px 10px;
    }
  }
}
