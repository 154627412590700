@import 'assets/stylesheets/abstracts';

.post-topic {
  .wrapper {
    @include flex-center(column);
    .name {
      width: 100%;
      padding: 0 5px;
      font-size: x-large;
    }

    .count {
      font-size: small;
      line-height: 1;
    }
  }

  .button {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 0 5px;
    font-size: initial;

    &.secondary {
      right: 25px;
      color: $secondary;

      &:hover {
        color: $primary;
      }
    }

    &.danger {
      color: $secondary;

      &:hover {
        color: $danger;
      }
    }
  }
}
