@import 'assets/stylesheets/abstracts';

.user-answers {
  margin: -1rem 0 -1rem;

  .table {
    background: $white;
    max-width: 600px;
    width: 100%;
    margin: auto;

    .row {
      .name {
      }

      .answer {
        text-align: center;
      }
    }
  }
}
