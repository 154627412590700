@import 'assets/stylesheets/abstracts';

.buttons {
  margin-right: 10px;
}

.filter-wrapper {
  margin-bottom: 2rem;
}

.competences {
  display: flex;
  flex-direction: column;

  .competence-group {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    .name {
      margin-right: 10px;
    }

    .button {
      padding: 0 5px;
      font-size: initial;

      &.secondary {
        color: $secondary;

        &:hover {
          color: $primary;
        }
      }

      &.danger {
        color: $secondary;

        &:hover {
          color: $danger;
        }
      }
    }
  }

  .add-button {
    align-self: flex-end;
  }
}

.user-answers-wrapper {
  margin-bottom: 2rem;
}

.modal-icon {
  font-size: 5rem;
  color: $danger;
}
