@import 'assets/stylesheets/abstracts';

.form-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 10px;

  .form {
    @include flex-center(row);
    flex: 1 1 auto;

    .inputs {
      flex: 1 1 auto;
      margin-bottom: 0;
      margin-right: 10px;
    }

    .buttons {
      flex: 0 1 auto;
    }
  }
}
