@mixin sidebar-base($image) {
  @include make-col-ready();
  background-image: url($image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25%;

  @include media-breakpoint-up(md) {
    width: 25%;
    height: 100%;
  }
}

@mixin flex-center($direction) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $direction;
}
