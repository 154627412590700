@import 'assets/stylesheets/abstracts';

.forgot-password-form {
  width: 90%;

  @include media-breakpoint-up(lg) {
    width: 70%;
  }

  .description {
    margin-bottom: 20px;

    p {
      text-align: justify;
    }
  }

  @include media-breakpoint-up(md) {
    .email-field {
      padding-right: 20%;
    }
  }

  .buttons {
    margin-top: 70px;

    @include media-breakpoint-up(sm) {
      padding-left: 50%;
    }
  }
}

a:hover {
  text-decoration: none !important;
}
