@import 'assets/stylesheets/abstracts';

.change-password-form {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  @include flex-center(column);
  flex-wrap: wrap;

  .form {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: stretch;
    flex: 1 1 auto;

    .form-wrapper {
      @include flex-center(row);
      flex-wrap: wrap;
      flex: 1 1 auto;
      padding: 0 10%;

      hr {
        width: 80%;
        border-width: 2px;
        margin: 20px auto;
      }

      .input {
        width: 100%;
      }
    }

    footer {
      width: 100%;
      text-align: right;
      padding-right: 1rem;

      button {
        width: 25%;
        margin: 0 5px;
      }
    }
  }
}
