@import 'assets/stylesheets/abstracts';

.notification-wrapper {
  position: fixed;
  z-index: 10000;
  top: 15px;
  width: 100%;
  padding: 0 15px;

  @include media-breakpoint-up(sm) {
    width: initial;
    top: 5%;
    left: 50%;
  }

  @include media-breakpoint-up(md) {
    left: initial;
    right: 15px;
    top: 10%;
    padding: 0;
  }

  .notification {
    @include media-breakpoint-up(sm) {
      left: -50%;
    }

    @include media-breakpoint-up(md) {
      left: initial;
    }
  }
}
