@import 'assets/stylesheets/abstracts';

.header {
  @include flex-center(row);
  margin-bottom: 1rem;

  .title {
    flex: 1 1 auto;
  }

  .button {
    flex: 0 1 auto;
    font-size: 2.5rem;
  }
}

.table {
  .main {
    width: 70%;
  }

  .actions {
    width: 30%;
  }
}

.answer-type-form {
  @include flex-center(column);
  width: 100%;
  height: 100%;
  margin-top: 20px;

  .form {
    @include flex-center(column);
    width: 100%;
    height: 100%;

    .form-wrapper {
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column;
      flex: 1 1 auto;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      padding: 5px;

      .wrapped {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-direction: column;

        .options {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;

          .option {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: row;
            width: 100%;
            margin-bottom: 1rem;

            .input {
              flex: 1 1 auto;
              padding-right: 10px;
              margin: 0;
              max-width: 29%;
            }

            .answer-buttons {
              @include flex-center(row);
            }
          }
        }
      }
    }

    footer {
      @include flex-center(column-reverse);
      width: 100%;
      height: fit-content;
      margin-top: 10px;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: flex-end;
      }

      .buttons {
        width: 100%;
        margin: 0;
        padding: 5px 0;

        @include media-breakpoint-up(sm) {
          width: 25%;
          padding: 0 0 0 10px;
        }

        button {
          width: 100%;
        }
      }
    }
  }
}

.modal-icon {
  font-size: 5rem;
  color: $danger;
}
