@import 'assets/stylesheets/abstracts';

.file-upload-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  .button {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: initial;
    }
  }

  .selected {
    list-style: none;
    font-size: small;
    padding: 0;
    margin-top: 10px;
    width: 100%;

    li {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      padding-right: 10px;

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1 1 auto;
      }

      .delete {
        flex: 0 1 auto;
        background: none;
        color: $danger;
        padding: 0 5px;
        border: none;
        height: 100%;
      }
    }
  }
}
