@import 'assets/stylesheets/abstracts';

.post {
  @include flex-center(column);
  width: 100%;
  background: $white;
  margin-bottom: 15px;

  @include media-breakpoint-up(md) {
    width: 80%;
  }

  @include media-breakpoint-up(lg) {
    align-items: flex-start;
    flex-direction: row;
    width: 90%;
    background: none;
  }

  header {
    @include flex-center(row);
    width: 100%;
    height: 50px;
    margin: 10px 0;
    padding: 0 10px;
    background: $white;

    @include media-breakpoint-up(lg) {
      flex-direction: column;
      justify-content: flex-start;
      width: 200px;
      height: initial;
      margin: 0;
      padding: 10px;
    }

    .avatar-wrapper {
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      border: 1px solid $darkgray;
      background: $whitish;
      width: 50px;
      height: 50px;
      flex: 0 1 auto;

      @include media-breakpoint-up(lg) {
        width: 100px;
        height: 100px;
      }

      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      .content {
        @include flex-center(column);
        position: absolute;
        width: 100%;
        height: 100%;

        .icon {
          color: $darkgray;
          font-size: 1rem;

          @include media-breakpoint-up(sm) {
            font-size: 1.5rem;
          }

          @include media-breakpoint-up(md) {
            font-size: 2rem;
          }
        }
      }
    }

    .middle {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 0 5px;
      height: 100%;
      flex: 1 1 auto;

      @include media-breakpoint-up(sm) {
        width: initial;
        flex: 1 1 auto;
      }

      @include media-breakpoint-up(lg) {
        height: initial;
        justify-content: flex-start;
        text-align: center;
        margin: 10px 0;
        flex: 0 1 auto;
      }

      .name {
        width: 100%;
        font-size: small;
        flex: 0 1 auto;
        line-height: 1.2;

        @include media-breakpoint-up(sm) {
          font-size: initial;
        }
      }

      .date {
        width: 100%;
        font-size: x-small;
        flex: 0 1 auto;

        @include media-breakpoint-up(sm) {
          font-size: smaller;
        }
      }
    }

    .right-side {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      @include media-breakpoint-up(lg) {
        flex-direction: column-reverse;
        flex: 1 1 auto;
        width: 100%;
      }

      .wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        height: 100%;
        width: fit-content;
        flex: 0 1 auto;

        @include media-breakpoint-up(sm) {
          width: initial;
        }

        @include media-breakpoint-up(lg) {
          @include flex-center(row);
          height: 100%;
          width: 100%;
          flex: 1 1 auto;
        }

        .button {
          @include flex-center(column);
          flex-wrap: wrap;
          font-size: small;
          width: fit-content;
          padding: 0;
          margin: 0 5px;

          @include media-breakpoint-between(sm, md) {
            flex-direction: row;
            flex-wrap: nowrap;
            font-size: initial;
          }

          .icon {
            font-size: x-large;
            flex: 0 1 auto;

            @include media-breakpoint-between(sm, md) {
              font-size: large;
              margin: 0 2px;
            }
          }

          .name {
            flex: 0 1 auto;
            margin-top: 1px;

            @include media-breakpoint-up(lg) {
              font-size: initial;
            }
          }
        }
      }
    }
  }

  .main {
    @include flex-center(column);
    width: 100%;
    background: $white;
    min-height: 275px;
    height: initial;
    align-self: stretch;

    .banner {
      @include flex-center(column);
      width: 100%;
      max-height: 200px;
      overflow: hidden;
    }

    .main-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-bottom: 5px;
      flex: 1 1 auto;

      .content {
        width: 100%;
        padding: 10px;
        flex: 1 1 auto;
        position: relative;

        .delete-post {
          color: $danger;
          font-weight: bold;
          position: absolute;
          top: 5px;
          right: 5px;
        }
      }

      .attachments {
        align-self: flex-end;
        width: fit-content;
        list-style: none;
        margin-right: 20px;
        padding: 0;
      }

      .interested {
        width: 100%;
        flex: 0 1 auto;
      }
    }
  }

  footer {
    background: $white;
    width: 100%;
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
      min-height: 275px;
      max-height: 650px;
      align-self: stretch;
      margin: 0;
      overflow-y: auto;
      overflow-x: hidden;
    }

    @include media-breakpoint-up(xl) {
      width: 60%;
    }
  }
}

.modal-icon {
  font-size: 5rem;
  color: $danger;
}
