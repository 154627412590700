@import 'assets/stylesheets/abstracts';

.title {
  align-self: stretch;
  padding: 20px 30px;

  hr {
    width: 40%;
    border-width: 2px;
    margin-left: -15px;
  }
}

.registration-form {
  width: 100%;
  padding: 0 5%;
  flex: 1 1 auto;
  @include flex-center(column);
  flex-wrap: wrap;

  .form {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: stretch;
    flex: 1 1 auto;

    .form-wrapper {
      @include flex-center(row);
      flex-wrap: wrap;
      flex: 1 1 auto;

      .left-side {
        @include flex-center(column);
        align-self: stretch;
        width: 100%;

        @include media-breakpoint-up(sm) {
          width: 50%;
        }

        .input {
          width: 100%;
        }
      }

      .right-side {
        @include flex-center(column);
        align-self: stretch;
        width: 100%;

        @include media-breakpoint-up(sm) {
          width: 50%;
        }

        .name-preview {
          font-size: x-large;
        }
      }
    }

    footer {
      flex: 0 1 auto;
      width: 100%;
      padding: 0;
      text-align: center;

      @include media-breakpoint-up(sm) {
        margin-left: 50%;
        width: 50%;
        padding: 0 5%;
      }

      @include media-breakpoint-up(lg) {
        padding: 0 10%;
      }

      .checkboxes {
        margin-bottom: 20px;
        padding-left: 30%;
        text-align: left;

        @include media-breakpoint-up(sm) {
          padding-left: 10%;
        }
      }
    }
  }
}
