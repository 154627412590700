@import 'assets/stylesheets/abstracts';

.posts {
  @include flex-center(column);
  margin: -10px -40px;

  h1 {
    align-self: flex-start;
    text-align: left;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      margin-left: 40px;
    }

    .active-topics {
      margin-left: 10px;
      color: $gray-600;
      font-weight: initial;
      font-size: xx-large;

      &:hover,
      &:focus {
        text-decoration: line-through;
      }
    }
  }
}
