@import 'assets/stylesheets/abstracts';

.registration-form {
  width: 100%;
  flex: 1 1 auto;
  @include flex-center(column);
  flex-wrap: wrap;

  .form {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: stretch;
    flex: 1 1 auto;

    .form-wrapper {
      @include flex-center(row);
      flex-wrap: wrap;
      flex: 1 1 auto;

      .left-side {
        @include flex-center(column);
        align-self: stretch;
        width: 100%;
        padding: 0 10px;

        @include media-breakpoint-up(sm) {
          width: 50%;
        }

        .input {
          width: 100%;
        }
      }

      .right-side {
        @include flex-center(column);
        align-self: stretch;
        width: 100%;

        @include media-breakpoint-up(sm) {
          width: 50%;
        }

        .name-preview {
          font-size: x-large;
        }
      }
    }

    footer {
      width: 100%;
      text-align: right;
      padding-right: 1rem;
      margin-top: 1rem;

      @include media-breakpoint-up(sm) {
        margin-top: 0;
      }

      button {
        width: 25%;
        margin: 0 5px;
      }
    }
  }
}
