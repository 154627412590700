@import 'assets/stylesheets/abstracts';

.dropdown-wrapper {
  @include flex-center(row);

  .dropdown-button {
    background: none;
    border: none;
    color: $gray-300;
    width: 100%;
    padding-bottom: 5px;
    outline: none;
    font-size: small;

    &:hover {
      color: $white;
    }

    &:active,
    &:focus,
    &:visited,
    &:hover {
      outline: none;
    }
  }

  .dropdown-menu {
    left: 50px;
  }
}
