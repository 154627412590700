@import 'assets/stylesheets/abstracts';

.app-sidebar {
  width: 160px;
  background-image: linear-gradient(60deg, $bg-dark 0%, $bg-light 100%);
  @include flex-center(column);

  @include media-breakpoint-up(md) {
    width: 320px;
  }

  header {
    background-image: linear-gradient(0deg, $bg-dark 45%, $bg-light 100%);
    padding: 15px 15px 2px;

    @include media-breakpoint-up(md) {
      padding: 20px 20px 10px;
    }

    .logo {
      margin-bottom: 20px;

      img {
        width: 100%;
      }
    }
  }

  main {
    width: 100%;
    height: initial;
    flex: 1 1 auto;

    hr {
      margin: 0.5em auto;
      border-width: 1px;
      border-color: $gray-500;
      width: 75%;
    }

    .nav-item {
      .nav-link {
        display: block;
        padding: 1rem 0.5rem;
        font-size: smaller;
        text-align: center;
        color: $white;

        @include media-breakpoint-up(sm) {
          font-size: initial;
        }

        @include media-breakpoint-up(md) {
          padding: 0.7rem 2rem;
          text-align: left;
          font-size: large;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
        }

        &:hover,
        &:active,
        &.active {
          background: $nav-hover;
          text-decoration: none;

          .icon {
            color: $primary;
          }
        }

        .icon {
          font-size: x-large;
          display: block;

          @include media-breakpoint-up(md) {
            font-size: large;
            margin-right: 10px;
            display: initial;
            width: 25px;
            text-align: center;
          }
        }

        .text {
          display: none;

          @include media-breakpoint-up(sm) {
            display: initial;
            font-size: 0.8rem;
          }

          @include media-breakpoint-up(md) {
            font-size: initial;
            //width: calc(100% - 50px);
          }
        }

        .toggle-indicator {
          margin-left: auto;
          width: 100%;

          @include media-breakpoint-up(md) {
            width: initial;
          }
        }
      }
    }

    .nav-item-child {
      background: $nav-child;
      border-left: solid $primary;
    }
  }

  footer {
    width: 100%;
    margin: 5px 0 0;
    flex: 0 1 auto;

    .nav-item {
      .nav-link {
        display: block;
        padding: 1rem 0.5rem;
        font-size: smaller;
        text-align: center;
        color: $white !important;
        cursor: pointer;

        @include media-breakpoint-up(sm) {
          font-size: initial;
        }

        @include media-breakpoint-up(md) {
          padding: 0.7rem 2rem;
          font-size: large;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
        }

        &:hover,
        &.active {
          background: $nav-hover;
          text-decoration: none;

          .icon {
            color: $primary;
          }
        }

        .icon {
          font-size: x-large;
          display: block;

          @include media-breakpoint-up(md) {
            font-size: large;
            margin-right: 10px;
            display: initial;
          }
        }

        .text {
          display: none;

          @include media-breakpoint-up(sm) {
            display: initial;
          }
        }
      }
    }
  }
}
