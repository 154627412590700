@import 'assets/stylesheets/abstracts';

.modal {
  max-width: 400px !important;
  height: fit-content !important;
  min-height: initial !important;

  .modal-content {
    @include flex-center(column);
    width: 100%;
    height: 100%;

    .modal-body {
      width: 100%;
      padding: 1.8rem 1.8rem 0rem !important;
      justify-content: center !important;
      align-items: center !important;
      flex-direction: column !important;
      flex-wrap: nowrap !important;
      text-align: center;
    }

    .modal-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      width: 100%;
      border: none;
    }
  }
}
