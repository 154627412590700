@import 'assets/stylesheets/abstracts';

.experience-select {
  width: 100%;
  padding: 0 10px;

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .inputs {
      @include flex-center(column);
      align-items: flex-start;
      width: 90%;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }

      .inputs-left {
        @include flex-center(row);
        align-items: flex-start;
        width: 100%;

        @include media-breakpoint-up(lg) {
          margin-right: 10px;
        }

        .workplace-field {
          margin-right: 10px;
          width: 50%;
        }

        .position-field {
          width: 50%;
        }
      }

      .inputs-right {
        @include flex-center(row);
        align-items: flex-start;
        width: 100%;

        .from-field {
          flex: 1 1 auto;
        }

        .minus {
          @include flex-center(row);
          margin: 0.8rem 10px 1rem;
        }

        .to-field {
          flex: 1 1 auto;
        }
      }
    }

    .remove-button {
      width: 10%;
      margin-bottom: 1rem;
    }
  }

  .add-button-wrapper {
    text-align: right;
    margin-top: -2rem;

    .add-button {
      font-size: small;
    }
  }
}
