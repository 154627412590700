@import 'assets/stylesheets/abstracts';

.welcome-image {
  position: relative;
  background-image: url('../../assets/images/welcome-image.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 35%;
  @include flex-center(row);

  .image-wrapper {
    width: 60%;

    img {
      width: 100%;
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    padding: 0;
    width: 100%;
    background: white;

    .disclaimer {
      font-size: xx-small;
      width: 90%;
      margin: auto;
      text-align: center;

      @include media-breakpoint-up(md) {
        width: 50%;
      }
    }

    @include media-breakpoint-up(md) {
      padding: 20px 0 10px;
    }
  }

  @include media-breakpoint-up(md) {
    width: 60%;
    height: 100%;

    .image-wrapper {
      width: 75%;
    }
  }

  @include media-breakpoint-up(lg) {
    justify-content: flex-end;
    align-items: flex-start;
    width: 70%;

    .image-wrapper {
      width: 40%;
      margin-right: 8%;
      margin-top: 20%;
    }
  }
}

.login-wrapper {
  width: 100%;
  height: 65%;
  padding-left: 0;
  @include flex-center(column);
  flex-wrap: nowrap;

  @include media-breakpoint-up(md) {
    @include make-col-ready();
    width: 40%;
    height: 100%;
  }

  @include media-breakpoint-up(lg) {
    width: 30%;
  }

  .login-form {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    flex: 1 1 auto;
    height: fit-content;
    width: 80%;

    .title {
      text-align: center;
      margin-bottom: 25px;
    }

    .forgot-your-password {
      margin-top: -0.5rem;
      margin-bottom: 0.5rem;
      text-align: right;
    }
  }

  .register-button {
    flex: 0 1 auto;
    width: 80%;
    margin: 0 0 30px;
  }
}
