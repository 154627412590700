@import 'assets/stylesheets/abstracts';

@mixin sidebar-button-base($image) {
  @include flex-center(row);
  background-image: url($image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(100%);
  height: 25%;
  width: 100%;
  cursor: pointer;
  transition: height 0.2s ease-in-out;

  .sidebar-text {
    text-transform: uppercase;
    font-weight: bold;
    color: $white;
    text-shadow: 3px 3px 4px $black;
    font-size: larger;
    transition: font-size 0.2s;
    text-align: center;
    word-wrap: break-spaces;
    margin: 0 3px;
  }

  &.active {
    height: 50%;
    filter: none;

    .sidebar-text {
      font-size: x-large;
    }
  }

  &:hover {
    filter: none;

    .sidebar-text {
      font-size: x-large;
    }
  }

  @include media-breakpoint-up(md) {
    width: 100%;
    height: 20%;
    flex-direction: column;

    &.active {
      height: 60%;
    }
  }
}

.modal-body {
  overflow-y: auto;
}

.sidebar {
  @include flex-center(column);
  flex-wrap: wrap;
  width: 100%;
  height: 25%;

  @include media-breakpoint-up(md) {
    width: 25%;
    height: 100%;
  }

  .personal-button {
    background: $gray-600;
    @include sidebar-button-base('../../assets/images/modal-image-3.jpg');
  }

  .studies-button {
    background: $gray-600;
    @include sidebar-button-base('../../assets/images/modal-image-2.jpg');
  }

  .company-button {
    background: $gray-600;
    @include sidebar-button-base('../../assets/images/modal-image-1.jpg');
  }
}
