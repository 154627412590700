@import 'assets/stylesheets/abstracts';

.manage-employees {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;

  .main-wrapper {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;

    h3 {
      margin: 20px 0;
    }

    .form {
      width: 100%;

      .form-wrapper {
        @include flex-center(row);
        flex-wrap: wrap;
        flex: 1 1 auto;

        .inputs {
          margin: 0 10px;
        }
      }
    }

    .add-button {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: row;
    }
  }
  footer {
    width: 100%;
    text-align: right;
    padding-right: 1rem;

    button {
      width: 25%;
    }
  }
}
