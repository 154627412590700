.modal-dialog {
  height: 90%;
  border-radius: 10px;
  overflow: hidden;

  @include media-breakpoint-up(sm) {
    width: 90%;
    max-width: 1000px;
  }

  .modal-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    height: 100%;
    margin: 0 auto;

    .modal-close {
      position: absolute;
      z-index: 1010;
      top: 0.5rem;
      right: 1rem;
      color: $white;
      text-shadow: 0 0 5px $bg-dark;
      cursor: pointer;

      @include media-breakpoint-up(md) {
        color: $bg-dark;
        text-shadow: none;
      }
    }

    .modal-body {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      align-content: flex-start;
      padding: 0;
      overflow: hidden;
      height: 100%;

      .modal-body-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: 75%;
        padding: 20px;

        @include media-breakpoint-up(md) {
          width: 75%;
          height: 100%;
        }
      }
    }
  }
}
