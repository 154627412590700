@import 'assets/stylesheets/abstracts';

.filter-wrapper {
  margin-bottom: 2rem;
  border: 1px solid $gray-600;
  border-radius: 10px;
  background: $broken-white;
  padding: 0.8rem 1rem;

  .buttons {
    width: 100%;
    margin-bottom: 10px;

    @include media-breakpoint-up(sm) {
      width: 25%;
      max-width: 150px;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
}
