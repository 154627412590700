@import 'assets/stylesheets/abstracts';

.form-wrapper {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;

  .competences {
    flex: 0 1 auto;

    .competence-group-name {
      text-decoration: none;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    @include media-breakpoint-up(sm) {
      flex-direction: row-reverse;
    }

    @include media-breakpoint-up(md) {
      width: 100%;
      justify-content: flex-start;
    }

    .button {
      @include media-breakpoint-up(sm) {
        margin: 0;

        &:last-child {
          margin-right: 10px;
        }
      }

      @include media-breakpoint-up(md) {
        max-width: 180px;
      }
    }
  }
}
