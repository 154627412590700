@import 'assets/stylesheets/abstracts';

.language-select {
  .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    .language-field {
      margin-right: 15px;
      margin-bottom: 0;
      width: 40%;
    }

    .level-field {
      width: 45%;
      margin-bottom: 0;
    }

    .remove-button {
      width: 15%;
    }
  }

  .add-button-wrapper {
    text-align: right;

    .add-button {
      margin-top: -1.5rem;
      font-size: small;
    }
  }
}
